<template v-if="$featureEnabled('appReleaseTool')">
  <v-app-bar title="App Releases / Mobile Apps">
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          icon="mdi-refresh"
          :loading="isSyncingAppStoreStates"
          :disabled="!isAppReleaseAdmin || isSyncingAppStoreStates"
          @click="syncAppStoreStates()"
        />
      </template>

      <span>Refresh app release states from app store</span>
    </v-tooltip>
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">App releases allow safe rollouts of new app versions</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="isAppReleaseAdmin">
            Your app release admin rights allow you to elevate releases to higher tier groups
          </template>

          <template v-else>You can only view app releases, apply for app release rights in the IT portal</template>
        </div>
      </v-col>
    </v-row>

    <template v-for="(releases, index) in [appReleaseCandidates, recentBuilds]" :key="`releases-${index}`">
      <v-sheet :class="index === 0 ? 'mb-12 mt-6' : 'my-12'">
        <v-data-table
          v-if="releases"
          disable-pagination
          hide-default-footer
          style="width: 100%; max-width: 100%; cursor: pointer"
          :items="releases"
          :headers="appHeaders"
          :loading="isLoadingReleases"
          :no-data-text="`No builds ${index === 0 ? 'selected as release candidates' : 'available for next release'}`"
        >
          <template #top>
            <div class="d-flex align-center">
              <h5 class="text-h5 text-primary pa-4">{{ index === 0 ? 'Release candidates' : 'Recent builds' }}</h5>

              <v-spacer />

              <template v-if="index === 0">
                <v-btn
                  rounded="1"
                  text="Release notes"
                  variant="outlined"
                  color="primary"
                  style="min-width: 200px"
                  @click="showReleaseNotes = true"
                />
              </template>
              <template v-else>
                <v-btn-toggle
                  v-model="buildsOs"
                  color="primary"
                  variant="outlined"
                  density="compact"
                  base-color="grey-darken-2"
                  style="border-color: rgb(33, 150, 243)"
                >
                  <v-btn text="Android" style="min-width: 100px" />

                  <v-btn text="iOS" style="min-width: 100px" />
                </v-btn-toggle>
              </template>
            </div>
          </template>

          <template #group-header="{ item, columns, toggleGroup, isGroupOpen }">
            <tr>
              <td :colspan="columns.length" @click="toggleGroup(item)">
                <v-btn class="text-none">
                  <v-icon>{{ isGroupOpen(item) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
                  {{ item.value === 'ios' ? 'iOS' : 'Android' }} ({{ item.items.length }})
                </v-btn>
              </td>
            </tr>
          </template>

          <template #item="{ item }">
            <ReleaseListItem
              :build="item"
              :is-selected="item.buildVersion === selectedBuild?.buildVersion"
              :is-older-build="
                index > 0 &&
                recentBuilds.indexOf(item) > recentBuilds.indexOf(recentBuilds.find((i) => i.tier === item.tier)!)
              "
              :has-group-header="index === 1"
              @edit-release="selectBuild($event)"
            />
          </template>
        </v-data-table>
      </v-sheet>
    </template>

    <PublicRelease :is-loading="latestReleases.length === 0" :releases="latestReleases" :type="ReleaseTypes.LATEST" />

    <PublicRelease
      :is-loading="previousReleases.length === 0"
      :releases="previousReleases"
      :type="ReleaseTypes.PREVIOUS"
    />

    <ReleasePanel :selected-build="selectedBuild" @close="selectedBuild = $event" />
  </v-container>

  <ReleaseNotesDialog v-model:open="showReleaseNotes" v-model:tab="defaultTab" :type="ReleaseTypes.CANDIDATE" />
</template>

<script lang="ts">
  import { sortBy } from 'lodash-es'

  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { appHeaders } from '#views/releases/constants'

  import { syncReleaseStates } from '#views/releases/utilities'

  import { AppStore, ReleasesStore } from '#stores'

  import { AppBuild, ReleaseTypes } from '#types/releases'

  @Component({})
  class ReleasesView extends Vue {
    public appHeaders = appHeaders

    public selectedBuild: AppBuild | undefined

    public buildsOs = 0
    public defaultTab = 'android'

    public showReleaseNotes = false

    public isSyncingAppStoreStates = false

    public readonly ReleaseTypes = ReleaseTypes

    private readonly appStore = new AppStore()
    private readonly releasesStore = new ReleasesStore()

    public get isLoadingReleases() {
      return this.releasesStore.loadingReleases
    }

    public get recentBuilds() {
      return this.builds
        .filter(
          (build) =>
            build.buildVersion !== undefined &&
            ((build.platform === 'android' && this.buildsOs === 0) ||
              (build.platform === 'ios' && this.buildsOs === 1)),
        )
        .reverse()
    }

    public get appReleaseCandidates() {
      const { android, ios } = this.releasesStore.releaseCandidates

      return sortBy(
        this.builds.filter((build) => build.buildVersion !== undefined && [android, ios].includes(build.buildVersion)),
        'buildVersion',
        'asc',
      )
    }

    public get isAppReleaseAdmin() {
      return this.appStore.isAppReleaseAdmin
    }

    private get builds() {
      return this.releasesStore.builds
    }

    public get latestReleases() {
      return Object.values(this.releasesStore.latestReleases)
    }

    public get previousReleases() {
      return Object.values(this.releasesStore.previousReleases)
    }

    public selectBuild(build: AppBuild) {
      this.selectedBuild = this.selectedBuild?.buildVersion === build.buildVersion ? undefined : build
    }

    public syncAppStoreStates() {
      this.isSyncingAppStoreStates = true

      syncReleaseStates().finally(() => (this.isSyncingAppStoreStates = false))
    }
  }

  export default toNative(ReleasesView)
</script>
