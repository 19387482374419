<template>
  <v-data-table
    v-if="override.rolloutOneOf?.$case === 'rollout' && override.rolloutOneOf.rollout.stages"
    hide-default-footer
    class="mt-4"
    no-data-text="No rollout steps configured"
    :headers="
      override.metadata?.informative?.labels.template.startsWith('percentage')
        ? rolloutStagesHeaders
        : rolloutGroupsHeaders
    "
    :items="visibleStages"
    :items-per-page="1000"
  >
    <template #header.percentile="{ column }">
      <span color="grey">{{ column.title }}</span>

      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-icon v-bind="props" size="small" color="primary" class="mt-n1 ml-1">mdi-information-outline</v-icon>
        </template>

        The estimation does not account for any other rollouts that may be prioritized above this rollout.
      </v-tooltip>
    </template>

    <template #item.icon="{ index }">
      <v-tooltip
        :text="
          getRolloutStageColor(index) === 'green'
            ? 'This will activate at the launch time'
            : stageTooltips[
                visibleStages[index]?.state ? rolloutStageStateToJSON(visibleStages[index].state!) : 'FUTURE'
              ]
        "
      >
        <template #activator="{ props }">
          <v-icon v-bind="props" :color="getRolloutStageColor(index)">
            {{
              rolloutStageIcons[
                visibleStages[index]?.state ? rolloutStageStateToJSON(visibleStages[index].state!) : 'FUTURE'
              ]
            }}
          </v-icon>
        </template>
      </v-tooltip>
    </template>

    <template #item.step="{ item, index }">
      <span
        :class="
          visibleStages[index]?.state && rolloutStageStateToJSON(visibleStages[index].state!) === 'CURRENT'
            ? 'font-weight-bold'
            : ''
        "
      >
        <template v-if="override.metadata?.informative?.labels.template.startsWith('percentage')">
          Step {{ override.rolloutOneOf.rollout.stages.indexOf(visibleStages[index]) + 1 }}:
        </template>
        {{
          overrideRolloutStatus(feature, 'disable') === 'INACTIVE' &&
          featureOverrideState(override, true) !== 'DISABLED'
            ? 'Enable'
            : 'Disable'
        }}{{
          visibleStages[index]?.state &&
          overrideRolloutStatus(override) !== 'DISABLED' &&
          rolloutStageStateToJSON(visibleStages[index].state!).replace('CURRENT', 'COMPLETED') === 'COMPLETED'
            ? 'd'
            : ''
        }}
        for

        <template v-if="override.metadata?.informative?.labels.template.startsWith('percentage')">
          {{ fixedPointToString(item.percentile) }}% of targeted users
        </template>
        <template v-else>
          {{
            fixedPointToString(
              override.criteria?.oneOf?.$case === 'and' &&
                override.criteria?.oneOf?.and?.expressions[0].oneOf?.$case === 'predicate' &&
                override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf?.$case === 'user' &&
                override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf?.user.oneOf?.$case === 'range' &&
                override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf.user.oneOf.range.lowerBound,
            )
          }}% -
          {{
            fixedPointToString(
              override.criteria?.oneOf?.$case === 'and' &&
                override.criteria?.oneOf?.and?.expressions[0].oneOf?.$case === 'predicate' &&
                override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf?.$case === 'user' &&
                override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf?.user.oneOf?.$case === 'range' &&
                override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf.user.oneOf.range.upperBound,
            )
          }}% of targeted users
        </template>
      </span>
    </template>

    <template #item.status="{ index }">
      <span
        :class="
          `text-${getRolloutStageColor(index)} ` +
          (visibleStages[index]?.state && rolloutStageStateToJSON(visibleStages[index].state!) === 'CURRENT'
            ? 'font-weight-bold'
            : '')
        "
      >
        {{
          overrideRolloutStatus(override) === 'DISABLED' || overrideRolloutStatus(override) === 'INACTIVE'
            ? 'Waiting for launch'
            : visibleStages.some((s, i) => i < index && !s.duration && s.type !== stageTypes.ACTUATED)
              ? 'Waiting for continue'
              : visibleStages[index]?.state && stageStatuses[rolloutStageStateToJSON(visibleStages[index].state!)]
        }}
      </span>
    </template>

    <template #item.percentile="{ index }">
      <span
        :class="
          visibleStages[index]?.state && rolloutStageStateToJSON(visibleStages[index].state!) === 'CURRENT'
            ? 'font-weight-bold'
            : ''
        "
      >
        {{
          $featureEnabled('estimatorService')
            ? estimatedPercentiles[index] || 'Calculating estimate...'
            : 'Not support currently'
        }}
      </span>
    </template>

    <template #item.started="{ index }">
      <span
        :class="
          visibleStages[index]?.state && rolloutStageStateToJSON(visibleStages[index].state!) === 'CURRENT'
            ? 'font-weight-bold'
            : ''
        "
      >
        {{ getRolloutStateSchedule(index) }}
      </span>
    </template>
  </v-data-table>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { RangeEstimateResponse } from '@jouzen/feature-mgmt-api/estimator_service'
  import {
    Rollout_Stage,
    Rollout_Stage_StageType,
    rollout_Stage_StageStateToJSON,
  } from '@jouzen/feature-mgmt-api/rollout'

  import {
    rolloutGroupsHeaders,
    rolloutStageIcons,
    rolloutStagesHeaders,
    rolloutStateColors,
    stageStatuses,
    stageTooltips,
  } from '#views/features/constants'

  import { featureOverrideState, fixedPointToString, overrideRolloutStatus } from '#views/features/utilities'

  import { FeaturesStore } from '#stores'

  import { Feature, Override, RolloutTarget } from '#types'

  @Component({})
  class StatusStages extends Vue {
    @Prop() public feature!: Feature
    @Prop() public override!: Override

    @Prop() public timeDisplay!: string
    @Prop() public showHistory!: boolean

    @Prop() public rolloutView!: RolloutTarget

    public estimatedCriteria = ''
    public estimatedPercentiles: any[] = []

    public readonly stageTypes = Rollout_Stage_StageType

    public readonly stageTooltips = stageTooltips
    public readonly stageStatuses = stageStatuses
    public readonly rolloutStageIcons = rolloutStageIcons
    public readonly rolloutStateColors = rolloutStateColors
    public readonly rolloutStagesHeaders = rolloutStagesHeaders
    public readonly rolloutGroupsHeaders = rolloutGroupsHeaders

    public readonly fixedPointToString = fixedPointToString

    public readonly featureOverrideState = featureOverrideState
    public readonly overrideRolloutStatus = overrideRolloutStatus

    public readonly rolloutStageStateToJSON = rollout_Stage_StageStateToJSON

    private readonly featuresStore = new FeaturesStore()

    public get visibleStages() {
      return (
        (this.override.rolloutOneOf?.$case === 'rollout' &&
          this.override.rolloutOneOf.rollout.stages.filter(
            (s) => this.showHistory || !s.state || rollout_Stage_StageStateToJSON(s.state) !== 'COMPLETED',
          )) ||
        []
      )
    }

    public get settingsIndex() {
      return parseInt(this.rolloutView.split('-')[1]) || 0
    }

    @Watch('visibleStages', { immediate: true })
    protected visibleStageseChanged() {
      this.estimatedPercentiles = []

      if (this.$featureEnabled('estimatorService')) {
        this.fetchEstimatedPercentiles()
      }
    }

    public getRolloutStageColor(index: number) {
      if (!this.visibleStages[index]?.state) {
        return 'green'
      } else {
        return overrideRolloutStatus(this.override) === 'INACTIVE' &&
          ((index === 0 && !this.override) ||
            (index === 0 &&
              this.visibleStages[index]?.state &&
              rollout_Stage_StageStateToJSON(this.visibleStages[index].state!) === 'FUTURE') ||
            (index !== 0 &&
              this.visibleStages[index - 1]?.state &&
              rollout_Stage_StageStateToJSON(this.visibleStages[index].state!) === 'FUTURE' &&
              rollout_Stage_StageStateToJSON(this.visibleStages[index - 1].state!) === 'CURRENT'))
          ? 'green'
          : this.visibleStages[index]?.state
            ? rolloutStateColors[rollout_Stage_StageStateToJSON(this.visibleStages[index].state!)]
            : !this.override
              ? 'info'
              : 'error'
      }
    }

    public getRolloutStateSchedule(index: number) {
      const instantLaunch = this.$dayjs().subtract(15, 'minute').unix()

      if (!this.override?.metadata?.informative?.additionalData?.launchAt) {
        return 'No launch time set yet'
      } else {
        if (this.visibleStages[index]?.scheduledAt) {
          return this.formatDateTime(this.visibleStages[index].scheduledAt! as any)
        } else {
          let scheduledAt = this.$dayjs(this.override?.metadata?.informative?.additionalData?.launchAt)

          if (this.override.rolloutOneOf?.$case === 'rollout') {
            for (let stage = 0; stage <= index; stage++) {
              if (this.visibleStages[stage]?.scheduledAt) {
                continue
              }

              if (scheduledAt.valueOf() <= instantLaunch) {
                return 'No valid launch time set'
              } else if (stage === index && scheduledAt.valueOf() >= instantLaunch) {
                return this.formatDateTime(scheduledAt.valueOf())
              } else if (stage === this.visibleStages.length - 1 || !this.visibleStages[stage].duration) {
                return 'Paused - needs manual continue'
              } else {
                const days = this.$dayjs.duration(parseInt(this.visibleStages[stage].duration!.seconds), 's').asDays()

                scheduledAt = scheduledAt.add(days, 'day')
              }
            }
          }
        }

        return 'Unknown - please report this issue'
      }
    }

    private formatDateTime(scheduledAt: string | number) {
      if (this.timeDisplay === 'utc') {
        return this.$dayjs(scheduledAt).utc().format('HH:mm - DD MMM YYYY UTC')
      } else if (this.timeDisplay === 'detroit') {
        return this.$dayjs(scheduledAt).tz('America/Detroit').format('HH:mm - DD MMM YYYY (z)')
      } else if (this.timeDisplay === 'helsinki') {
        return this.$dayjs(scheduledAt).tz('Europe/Helsinki').format('HH:mm - DD MMM YYYY (z)')
      } else if (this.timeDisplay === 'relative') {
        const d = this.$dayjs.duration(this.$dayjs(scheduledAt).diff())

        return (
          (d.milliseconds() >= 0 ? 'In ' : '') +
          (['days', 'hours', 'minutes'].reduce((acc, cur) => {
            const value = Math.abs((d as any)[cur]())

            if (value > 0) {
              return `${acc} ${value} ${value === 1 ? cur.slice(-1) : cur}`
            }

            return acc
          }, '') +
            (d.milliseconds() > 0 ? '' : ' ago'))
        )
      } else {
        return this.$dayjs(scheduledAt).format('HH:mm - DD MMM YYYY (z)')
      }
    }

    public async fetchEstimatedPercentiles() {
      this.estimatedCriteria = this.override?.metadata?.uid || ''

      const estimateResponse = (await this.featuresStore.fetchDataFromEstimatorService({
        template: this.override?.metadata?.informative?.labels?.template,
        expression: this.override?.criteria,
      })) as RangeEstimateResponse

      if (this.estimatedCriteria === this.override?.metadata?.uid) {
        this.estimatedPercentiles = await Promise.all(
          this.visibleStages.map((stage: Rollout_Stage) =>
            this.calculateEstimatedUserPercentile(estimateResponse, stage),
          ),
        )
      }
    }

    private async calculateEstimatedUserPercentile(
      estimateResponse: RangeEstimateResponse,
      rolloutStage: Rollout_Stage,
    ) {
      if (!estimateResponse || estimateResponse?.percentageOfUsersAffected?.integerPart == null) {
        return 'Estimation failed'
      } else if (
        (!estimateResponse?.percentageOfUsersAffected?.integerPart &&
          !estimateResponse?.percentageOfUsersAffected?.thousandthPart) ||
        (!rolloutStage?.percentile?.integerPart && !rolloutStage?.percentile?.thousandthPart)
      ) {
        return 'Very few or no users'
      } else {
        const affectedUserPercentage = parseFloat(
          [
            (estimateResponse?.percentageOfUsersAffected?.integerPart || 0).toString(),
            ('000000' + (estimateResponse?.percentageOfUsersAffected?.thousandthPart || 0)).slice(-3),
          ].join('.'),
        )

        const rolloutStagePercentage = parseFloat(
          [
            (rolloutStage?.percentile?.integerPart || 0).toString(),
            ('000000' + (rolloutStage?.percentile?.thousandthPart || 0)).slice(-3),
          ].join('.'),
        )

        const percent = Math.ceil(affectedUserPercentage * (rolloutStagePercentage / 100))

        return percent > 1 ? '~' + percent + '% of total user base' : 'Less than one percent'
      }
    }
  }

  export default toNative(StatusStages)
</script>
