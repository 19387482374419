<template>
  <v-dialog :model-value="routeParams.calendarOpen === 'true'">
    <v-card :loading="filtering">
      <v-card-title class="d-flex justify-space-between align-center ml-2">
        <div class="d-flex align-center">
          <span class="mr-2">Feature flag rollouts</span>

          <v-tooltip location="top" max-width="300px">
            <template #activator="{ props }">
              <v-icon v-bind="props" size="extra-small" color="primary">mdi-information-outline</v-icon>
            </template>

            <p>
              On the calendar, red dot means first step rollout and blue dot means advancement to next step in the
              rollout.
            </p>
          </v-tooltip>
        </div>

        <v-btn icon="mdi-close" color="primary" @click="closeDialog()" />
      </v-card-title>

      <v-card-text>
        <v-container class="pt-0">
          <v-row class="justify-space-between">
            <v-col cols="6">
              <v-text-field
                v-model.trim="routeParams.rolloutSearch"
                rounded
                clearable
                hide-details
                density="compact"
                variant="outlined"
                label="Filter shown rollouts..."
                append-inner-icon="mdi-magnify"
                @click:clear="routeParams.rolloutSearch = ''"
              />
            </v-col>

            <v-col cols="3">
              <v-text-field
                v-model.trim="routeParams.minAppVersion"
                rounded
                clearable
                hide-details
                variant="outlined"
                persistent-placeholder
                density="compact"
                label="Minimum mobile app version"
                placeholder="e.g. 5.1.2"
                :rules="[(v) => v === '' || validate(v) || 'Invalid version format']"
                @click:clear="routeParams.minAppVersion = ''"
              />
            </v-col>

            <v-col cols="auto">
              <v-menu offset="8" max-height="500" :close-on-content-click="false">
                <template #activator="{ props: menu }">
                  <v-tooltip location="top" max-width="300px">
                    <template #activator="{ props: tooltip }">
                      <v-btn
                        v-bind="mergeProps(menu, tooltip)"
                        rounded
                        size="small"
                        icon="mdi-filter-variant-plus"
                        :active="routeParams.productionOnly === 'true'"
                      />
                    </template>

                    <span>Filter options</span>
                  </v-tooltip>
                </template>

                <v-list>
                  <v-list-subheader>Filtering options</v-list-subheader>

                  <v-list-item>
                    <v-switch
                      label="Show only production rollouts"
                      style="white-space: nowrap"
                      :model-value="routeParams.productionOnly === 'true'"
                      @update:model-value="routeParams.productionOnly = $event ? 'true' : 'false'"
                    />
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-alert v-if="project && projectRolloutsOnly" type="info">
                <v-row>
                  <v-col>
                    <p>{{ `Viewing rollouts for project: ${project?.name}.` }}</p>
                  </v-col>

                  <v-col class="flex-shrink-1 text-right">
                    <v-btn text="Show all rollouts" color="primary" @click="showAllRollouts()" />
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>

          <v-row>
            <v-sheet fill-height style="width: 100%">
              <v-row class="mt-n4 mb-4">
                <v-col>
                  <div class="text-h6 font-weight-light">
                    Currently active feature flags: {{ activeFeatures.length }} / {{ allFeatures.length }}
                    <span class="mx-4">|</span>
                    Currently active rollouts: {{ filteredOverrides.length }} / {{ totalOverridesCount }}
                  </div>
                </v-col>
              </v-row>

              <v-calendar
                v-model="calendarValue"
                color="primary"
                :events="rolloutCalendarEvents"
                :view-mode="calendarViewType"
                :weekdays="[1, 2, 3, 4, 5, 6, 0]"
              />
            </v-sheet>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { mergeProps } from 'vue'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { compareVersions, validate } from 'compare-versions'

  import { Debounce } from '@jouzen/outo-toolkit-vuetify'

  import { Root } from '@jouzen/feature-mgmt-api/predicates'
  import { Rollout_Stage, Rollout_Stage_StageType } from '@jouzen/feature-mgmt-api/rollout'

  import { calendarViewTypes } from '#views/features/constants'

  import {
    featureOverrideState,
    fixedPointToString,
    forEachFeatureOverride,
    forEachPredicateExpression,
    overrideRolloutStatus,
  } from '#views/features/utilities'
  import { getProjectName, getProjectTeam } from '#views/projects/utilities'

  import { FeaturesStore, ProjectsStore } from '#stores'

  import { CalendarViewType, Feature, Override, Reference } from '#types'

  @Component({})
  class FeaturesState extends Vue {
    @Prop() public routeParams: any = {}

    public filtering = true
    public projectRolloutsOnly = true
    public filteredOverrides: Reference[] = []
    public calendarValue: Date[] = [new Date()]
    public calendarViewType: CalendarViewType = 'month'

    public readonly stageTypes = Rollout_Stage_StageType
    public readonly calendarViewTypes = calendarViewTypes

    public readonly validate = validate
    public readonly mergeProps = mergeProps
    public readonly getProjectTeam = getProjectTeam
    public readonly getProjectName = getProjectName

    private readonly featuresStore = new FeaturesStore()
    private readonly projectStore = new ProjectsStore()

    public get loading() {
      return this.featuresStore.loading || this.projectStore.loading || this.filtering
    }

    public get allFeatures() {
      return this.featuresStore.features
    }

    public get project() {
      return this.projectStore.project
    }

    public get activeFeatures() {
      return this.allFeatures.filter((feature: Feature) => {
        let isActive = false

        forEachFeatureOverride(feature, (override: Override) => {
          if (
            override.rolloutOneOf?.$case === 'rollout' &&
            override.rolloutOneOf.rollout.stages.some((stage: Rollout_Stage) => {
              return stage.scheduledAt
            })
          ) {
            isActive = true
          }
        })

        return isActive
      })
    }

    public get activeOverrides() {
      const overrides: Reference[] = []

      this.allFeatures.forEach((feature: Feature) => {
        forEachFeatureOverride(feature, (override) => {
          if (
            overrideRolloutStatus(override) !== 'DISABLED' &&
            overrideRolloutStatus(override) !== 'INACTIVE' &&
            overrideRolloutStatus(feature, 'disable') === 'INACTIVE'
          ) {
            overrides.push({ feature, overrides: [override] })
          }
        })
      })

      return overrides
    }

    public get totalOverridesCount() {
      return this.allFeatures.reduce((acc: number, feature: Feature) => {
        forEachFeatureOverride(feature, () => {
          acc++
        })

        return acc
      }, 0)
    }

    public get rolloutCalendarEvents() {
      const rolloutCalendarEvents = this.filteredOverrides
        .flatMap((r: Reference) => {
          const stages: Rollout_Stage[] =
            (r.overrides[0].rolloutOneOf?.$case === 'rollout' && r.overrides[0].rolloutOneOf.rollout.stages) || []

          let scheduledAt = this.$dayjs(stages[0].scheduledAt)

          return stages
            .map((_rolloutStage, stageIndex) => {
              let stageDuration = 0

              if (stageIndex > 0) {
                if (stages.slice(0, stageIndex).every((s: any) => s.duration)) {
                  stageDuration = this.$dayjs.duration(parseInt(stages[stageIndex - 1].duration!.seconds), 's').asDays()

                  scheduledAt = scheduledAt.add(stageDuration, 'day')
                } else {
                  return null
                }
              }

              const eventTime = this.$dayjs(scheduledAt).format('HH:mm')

              const eventTitle = this.getCalendarEventTitle(r.overrides[0], stageIndex)

              return {
                title: `${eventTime}: ${r.feature.metadata?.name.toUpperCase()} - ${eventTitle}`,
                start: scheduledAt.toDate(),
                end: scheduledAt.toDate(),
                color: stageIndex === 0 ? 'red' : 'blue',
                allDay: false,
              }
            })
            .filter(Boolean)
        })
        .sort((a, b) => a!.start.getTime() - b!.start.getTime())

      return rolloutCalendarEvents
    }

    @Watch('allFeatures', { immediate: true })
    public allFeaturesChange() {
      this.filtering = true

      this.filterOverrides()
    }

    @Watch('project', { immediate: true })
    public projectChange() {
      this.filterOverrides()
    }

    @Watch('projectRolloutsOnly', { immediate: true })
    public projectRolloutsChange() {
      this.filterOverrides()
    }

    @Watch('routeParams.rolloutSearch')
    @Watch('routeParams.minAppVersion')
    @Watch('routeParams.productionOnly', { immediate: true })
    public routeParamsChange() {
      this.filterOverrides()
    }

    public showAllRollouts() {
      this.projectRolloutsOnly = false
    }

    @Debounce(500)
    public filterOverrides() {
      this.filtering = true

      let baseList = this.activeOverrides

      // Apply project filter if project is selected and showOnlyProjectRollouts is enabled
      if (this.project && this.projectRolloutsOnly) {
        baseList = baseList.filter((r: Reference) => {
          return r.feature?.metadata?.informative?.labels?.project === this.project?.id
        })
      }

      // Apply production rollouts filter if enabled
      if (this.routeParams.productionOnly === 'true') {
        baseList = baseList.filter((r: Reference) => {
          return (
            r.overrides[0]?.metadata?.informative?.labels?.template?.startsWith('percentage-release') ||
            r.overrides[0]?.metadata?.informative?.labels?.template?.startsWith('percentage-ouranians') ||
            r.overrides[0]?.metadata?.informative?.labels?.template?.startsWith('experiment-')
          )
        })
      }

      // Apply search filter
      if (this.routeParams.rolloutSearch) {
        const search = this.routeParams.rolloutSearch.toLowerCase()

        baseList = baseList.filter((r: Reference) => {
          const featureMetadata = r.feature.metadata

          const overrideMetadata = r?.overrides[0]?.metadata

          return (
            featureMetadata?.name.toLowerCase().includes(search) ||
            featureMetadata?.changeRecord?.createdBy?.toLowerCase()?.includes(search) ||
            featureMetadata?.informative?.additionalData?.createdBy?.toLowerCase()?.includes(search) ||
            overrideMetadata?.informative?.additionalData?.createdBy?.toLowerCase().includes(search) ||
            overrideMetadata?.informative?.displayName?.toLowerCase().includes(search) ||
            (this.project &&
              this.projectRolloutsOnly &&
              (getProjectName(r.feature)?.toLowerCase()?.includes(search) ||
                getProjectTeam(r.feature)?.toLowerCase()?.includes(search)))
          )
        })
      }

      // Apply minimum mobile app version filter
      if (this.routeParams.minAppVersion && validate(this.routeParams.minAppVersion)) {
        baseList = baseList.filter((r: Reference) => {
          const mobileAppVersion = this.getMobileAppVersion(r)

          return mobileAppVersion && compareVersions(mobileAppVersion, this.routeParams.minAppVersion) >= 0
        })
      }

      this.filteredOverrides = baseList

      this.filtering = false
    }

    public closeDialog() {
      this.routeParams.calendarOpen = 'false'
      this.routeParams.productionOnly = 'true'

      this.routeParams.rolloutSearch = ''
      this.routeParams.minAppVersion = ''

      this.projectRolloutsOnly = true
    }

    private getMobileAppVersion(reference: Reference): string | null {
      const criteria = reference?.overrides[0]?.criteria?.oneOf

      if (!criteria) {
        return null
      }

      let mobileVersion = null

      forEachPredicateExpression(criteria, (predicate: Root) => {
        if (
          predicate.oneOf?.$case === 'client' &&
          predicate.oneOf?.client.oneOf?.$case === 'mobileApp' &&
          predicate.oneOf?.client.oneOf?.mobileApp.oneOf?.$case === 'version' &&
          predicate.oneOf?.client.oneOf?.mobileApp.oneOf?.version.oneOf?.$case === 'min'
        ) {
          const version = predicate.oneOf?.client.oneOf?.mobileApp.oneOf?.version.oneOf?.min

          mobileVersion = `${version.major}.${version.minor}.${version.patch}`
        }
      })

      return mobileVersion
    }

    private getCalendarEventTitle(override: Override, stageIndex: number) {
      const rolloutType = override?.metadata?.informative?.labels?.template?.split('-')[0]

      const rolloutStage = ((override.rolloutOneOf?.$case === 'rollout' && override.rolloutOneOf.rollout.stages) || [])[
        stageIndex
      ]

      const featureStatus = featureOverrideState(override) !== 'DISABLED' ? 'Enable' : 'Disable'

      if (rolloutType === 'percentage') {
        const overrideName = override.metadata!.uid!.split('_').slice(0, -1).join('_')

        const days = rolloutStage.duration
          ? this.$dayjs.duration(parseInt(rolloutStage.duration.seconds), 's').asDays()
          : 0

        const stageDuration =
          rolloutStage.type === Rollout_Stage_StageType.ACTUATED
            ? 'paused'
            : rolloutStage.type === Rollout_Stage_StageType.FINALIZING
              ? 'infinite'
              : `${days} ${days === 1 ? 'day' : 'days'}`

        const stagePercentile = fixedPointToString(rolloutStage.percentile)

        return `${overrideName.toUpperCase()} - Step ${stageIndex + 1}: ${featureStatus} for ${stagePercentile}% (${stageDuration})`
      } else {
        const groupName =
          override.metadata?.informative?.displayName || `Group ${'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[stageIndex]}`

        const lowerPercentile = fixedPointToString(
          override.criteria?.oneOf?.$case === 'and' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.$case === 'predicate' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf?.$case === 'user' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf?.user.oneOf?.$case === 'range' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf.user.oneOf.range.lowerBound,
        )

        const upperPercentile = fixedPointToString(
          override.criteria?.oneOf?.$case === 'and' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.$case === 'predicate' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf?.$case === 'user' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf?.user.oneOf?.$case === 'range' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf.user.oneOf.range.upperBound,
        )

        const enableFor = `${lowerPercentile}% - ${upperPercentile}%`

        return `${groupName.toUpperCase()} - Enable for ${enableFor}`
      }
    }
  }

  export default toNative(FeaturesState)
</script>

<style lang="scss" scoped>
  :deep(.v-calendar) {
    .v-chip {
      padding: 12px !important;
      margin-bottom: 4px !important;
      border-radius: unset !important;
    }

    .v-chip__content {
      display: block;
      overflow: scroll;
      color: rgb(33 150 243);
    }

    .v-chip.v-chip--density-comfortable {
      height: auto !important;
      text-wrap: wrap;
    }

    .v-calendar-weekly__day-label .v-btn {
      margin-top: 8px;
      margin-bottom: -12px;
      color: white !important;
      background-color: rgb(200 200 200) !important;
    }
    .v-calendar-weekly__day-label .v-btn.v-calendar-weekly__day-label__today {
      color: white !important;
      background-color: rgb(33 150 243) !important;
    }
    .v-chip--variant-tonal .v-chip__underlay {
      background-color: rgba(33, 150, 243, 0.5) !important;
    }
  }

  :deep(.v-switch) {
    .v-selection-control {
      justify-content: end;
    }
  }
</style>
