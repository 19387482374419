<template>
  <v-dialog v-model="isOpen" scrollable width="800px" @click:outside="close()">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text>
        <v-tabs v-model="activeTab" class="mb-4" align-tabs="end">
          <v-tab value="android">Android</v-tab>
          <v-tab value="ios">iOS</v-tab>
        </v-tabs>

        <v-alert outlined class="mb-4" :type="infoType" :text="infoText" />

        <v-select
          v-if="allowEdit"
          v-model="selectedTemplate"
          label="Release note templates"
          :loading="fetchingTemplates"
          :items="templates"
          @update:model-value="updateReleaseNotesText($event)"
        />

        <v-textarea
          v-model="releaseNotesText"
          no-resize
          auto-grow
          max-rows="15"
          label="Release notes"
          :text="releaseNotesText"
          :readonly="!allowEdit"
          :rows="5"
          @update:model-value="updateReleaseNotesText($event)"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn rounded="1" :text="isReleaseCandidate ? 'Close without saving' : 'Close'" @click="close()" />

        <v-btn
          v-if="isReleaseCandidate"
          text="Save"
          rounded="1"
          variant="flat"
          color="primary"
          :disabled="!allowEdit"
          @click="saveReleaseNotes()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { fetchReleaseNoteTemplateTranslations } from './utilities'

  import { capitalize } from 'lodash-es'

  import { Component, Model, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { ReleasesStore } from '#stores'

  import { AppPlatform, ReleaseNoteStates, ReleaseTypes } from '#types'

  @Component({})
  class ReleaseNotesDialog extends Vue {
    @Model({ name: 'open', required: true }) public isOpen!: boolean

    @Model({ name: 'tab', required: false }) public activeTab!: AppPlatform

    @Prop({ required: true }) public type!: ReleaseTypes

    @Prop({ required: false, default: undefined }) public afterClose?: () => void | undefined

    private emptyTemplate = {
      title: 'No template',
      value: '',
      id: '',
    }
    public templates: { title: string; value: string; id: string }[] = [this.emptyTemplate]

    public selectedTemplate = ''
    public releaseNotesText = ''
    public fetchingTemplates = false

    private keyId = ''

    private readonly releasesStore = new ReleasesStore()

    public get title() {
      return `${capitalize(this.type)}s`
    }

    public get allowEdit() {
      if (!this.isReleaseCandidate) {
        return false
      }

      return [ReleaseNoteStates.DRAFT, ReleaseNoteStates.TEMPLATE_TRANSLATION, undefined].includes(
        this.releaseNotes?.state,
      )
    }

    public get isReleaseCandidate() {
      return this.type === ReleaseTypes.CANDIDATE
    }

    public get infoType() {
      return this.releaseNotes?.state === ReleaseNoteStates.TRANSLATED ? 'success' : 'info'
    }

    public get infoText() {
      if (this.releaseNotes?.state === ReleaseNoteStates.TRANSLATED) {
        return 'Release notes have been translated.'
      } else if (this.releaseNotes?.state === ReleaseNoteStates.TEMPLATE_TRANSLATION) {
        return 'Release note template selected. Using a template will skip the translation request step.'
      } else if (this.releaseNotes?.state === ReleaseNoteStates.IN_TRANSLATION) {
        return 'Release notes are being translated. No editing allowed via Waltari, contact translation team for any edits.'
      }

      if (!this.allowEdit) {
        return 'Release notes status is not available, No editing allowed via Waltari.'
      }

      return 'Release notes are in draft state.'
    }

    private get releaseNotes() {
      return this.releasesStore.releaseNotes[this.type][this.activeTab]
    }

    @Watch('isOpen')
    public async openChanged() {
      if (!this.isOpen) {
        this.selectedTemplate = ''

        return
      }

      this.updateReleaseNotesText(this.releaseNotes?.notes)

      if (this.type === ReleaseTypes.CANDIDATE && this.templates.length <= 1) {
        this.fetchingTemplates = true

        const templateTranslations = await fetchReleaseNoteTemplateTranslations()
        const releaseNoteTemplates = templateTranslations.map((translation) => ({
          id: translation?.key?.id ?? '',
          title: translation.keyName,
          value: translation?.content ?? 'No en-US translation available, let the translations team know.',
        }))

        this.fetchingTemplates = false

        this.templates = [this.emptyTemplate, ...releaseNoteTemplates]
      }
    }

    @Watch('activeTab')
    public async tabChanged() {
      this.updateReleaseNotesText(this.releaseNotes?.notes)
    }

    @Watch('templates')
    public templatesChanged() {
      const activeTemplate = this.templates.find((template) => template.value === this.releaseNotesText)

      this.updateReleaseNotesText(activeTemplate?.value)
    }

    public close() {
      this.isOpen = false
      this.updateReleaseNotesText('')

      if (this.afterClose) {
        this.afterClose()
      }
    }

    public async saveReleaseNotes() {
      const { major, minor, patch } = await this.releasesStore.fetchLatestVersion(this.activeTab)
      const version = `${major}.${minor}.${patch}`

      const data = {
        keyId: this.keyId,
        notes: this.releaseNotesText,
        state: ReleaseNoteStates[this.keyId ? 'TEMPLATE_TRANSLATION' : 'DRAFT'],
      }

      this.releasesStore.updateReleaseNotesState(version, this.activeTab, data)

      this.close()
    }

    public updateReleaseNotesText(text: string | undefined) {
      this.releaseNotesText = text || ''

      this.updateTemplate(this.releaseNotesText)
    }

    private updateTemplate(releaseNotesText: string) {
      const activeTemplate = this.templates.find((template) => template.value === releaseNotesText)

      this.keyId = activeTemplate?.id ?? ''
      this.selectedTemplate = activeTemplate?.value ?? ''
    }
  }

  export default toNative(ReleaseNotesDialog)
</script>
