<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-dialog v-model="isOpen" scrollable width="1000" :persistent="uploading" @click:outside="close()">
    <v-card>
      <v-card-title>
        <v-tabs v-model="activeTab">
          <v-tab value="upload">Upload File</v-tab>
          <v-tab v-if="type === 'image' && !slug" value="import">Import From Figma</v-tab>
          <v-tab v-if="!slug" value="select">Select From Filero</v-tab>
        </v-tabs>
      </v-card-title>

      <v-card-text class="mb-n2">
        <v-alert v-if="error" class="mb-4" type="error" :text="error" />

        <v-row>
          <v-col cols="7">
            <v-window v-model="activeTab">
              <v-window-item value="upload">
                <v-file-input
                  v-model="mediaFile"
                  style="min-width: 550px"
                  prepend-icon="mdi-cloud-upload-outline"
                  :label="'Select ' + type + ' file to upload'"
                  :accept="type + '/*'"
                  @update:model-value="mediaFileSelected($event as File)"
                />

                <v-divider class="my-6" />

                <v-row v-if="type !== 'image'">
                  <v-col :cols="!variantUpload ? 4 : 12">
                    <v-select
                      v-model="language"
                      return-object
                      label="Language"
                      prepend-icon="mdi-information-outline"
                      :items="languages"
                      :disabled="!mediaFile || type === 'video'"
                    />
                  </v-col>

                  <v-col v-if="!variantUpload" cols="4">
                    <v-combobox
                      v-model="provider"
                      label="Vendor"
                      hide-selected
                      :items="vendors"
                      :disabled="!mediaFile"
                    />
                  </v-col>

                  <v-col v-if="!variantUpload" cols="4">
                    <v-select v-model="category" label="Category" :items="categories" :disabled="!mediaFile" />
                  </v-col>
                </v-row>

                <template v-else>
                  <v-combobox
                    v-model="provider"
                    label="Vendor"
                    hide-selected
                    prepend-icon="mdi-information-outline"
                    :items="vendors"
                    :disabled="!mediaFile"
                  />

                  <v-file-input
                    v-model="thumbFile"
                    class="mt-n3"
                    style="min-width: 550px"
                    prepend-icon="mdi-image"
                    accept="image/*"
                    label="Select thumbnail for the image file"
                    :disabled="!mediaFile || generateThumb"
                  />

                  <v-checkbox v-model="generateThumb" label="Use auto generated thumbnail image" class="mt-0 mb-n6" />
                </template>

                <div v-if="!variantUpload" class="mt-n2 mb-8 ml-10">
                  <v-alert
                    v-if="(contents && contents.find((c) => c.id === slug)) || mediaFiles.find((m) => m.slug === slug)"
                    type="warning"
                    border="start"
                    text="Media file with given slug already exists. If you are trying to add a language variant instead of new content then edit the existing content and add the language variant from there."
                  />

                  <template v-else-if="type !== 'image'">
                    <v-alert
                      v-if="language.value === 'any'"
                      type="info"
                      text="Content without any spoken language will be shown with all app languages, no language variants needed"
                    />

                    <v-alert
                      v-else-if="language.value === 'en'"
                      type="info"
                      text="English spoken content will be shown with all app languages and its possible to add other language variants"
                    />

                    <v-alert
                      v-else
                      type="info"
                      variant="text"
                      :text="`${language.title} content will only be shown with the same app language, no language variants can be added`"
                    />
                  </template>
                </div>

                <v-divider class="my-6" />

                <v-text-field
                  v-model="slug"
                  label="Slug"
                  prepend-icon="mdi-identifier"
                  :rules="[validateSlug]"
                  :disabled="!mediaFile || variantUpload"
                />
              </v-window-item>

              <v-window-item v-if="type === 'image'" value="import">
                <v-select v-model="selectedFigmaFile" label="Figma file to import from" :items="availableFigmaFiles" />
                <v-text-field v-model="filter" label="Filter images" />

                <div style="min-height: 350px; max-height: 350px; overflow: auto">
                  <v-data-table
                    v-model="selected"
                    class="mt-n3"
                    disable-pagination
                    hide-default-footer
                    single-select
                    item-value="slug"
                    :items="figmaMedia"
                    :headers="headers"
                    :loading="!!selectedFigmaFile && previewing"
                    :items-per-page="1000"
                    :no-data-text="
                      !!selectedFigmaFile
                        ? previewing
                          ? 'Loading images...'
                          : 'No images found'
                        : 'Select file from dropdown'
                    "
                    @click:row="(_event: any, row: any) => figmaImageSelected(row.item)"
                  />
                </div>
              </v-window-item>

              <v-window-item value="select">
                <v-text-field v-model="filter" label="Filter content" />

                <div style="min-height: 350px; max-height: 350px; overflow: auto">
                  <v-data-table
                    v-model="selected"
                    class="mt-n3"
                    disable-pagination
                    hide-default-footer
                    single-select
                    item-value="slug"
                    :items="media"
                    :headers="headers"
                    :loading="listing"
                    :items-per-page="1000"
                    :no-data-text="listing ? 'Loading media...' : 'No media found'"
                    @click:row="(_event: any, row: any) => fileroObjectSelected(row.item)"
                  />
                </div>
              </v-window-item>
            </v-window>
          </v-col>

          <v-col
            cols="5"
            class="d-flex flex-column fill-height px-6"
            style="min-height: 408px; max-height: 408px; overflow: auto"
          >
            <div class="white" style="font-size: 12px; width: 100%; text-align: center">Preview</div>

            <div class="d-flex align-center mt-3 bg-grey-lighten-3" style="min-height: 340px">
              <MediaFilePreview
                :loading="fetching"
                :media-url="previewUrl"
                :media-type="type"
                :thumb-url="previewThumbUrl"
              />
            </div>

            <div
              v-if="activeTab === 'import' && !validateFigmaImageInfo(figmaImage)"
              style="text-align: center; color: red"
            >
              Image has missing/incorrect info. Fix to proceed
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex flex-row align-center" style="overflow: hidden">
        <v-checkbox
          v-if="activeTab === 'select'"
          v-model="showActive"
          class="font-weight-regular my-n1"
          label="Display already in use media instead of new media"
        />
        <v-checkbox
          v-else-if="activeTab === 'import'"
          v-model="updateExisting"
          class="font-weight-regular my-n1"
          label="Update already imported media instead of importing new media"
          :disabled="!current"
        />
        <v-checkbox
          v-else-if="activeTab === 'upload'"
          v-model="activateVariant"
          label="Activate new version immediately after the upload is completed"
        />

        <v-spacer />

        <v-btn v-if="activeTab === 'import'" text="Fix Image info" @click="figmaImageCorrectionOpen = true" />

        <v-btn text="Cancel" :disabled="uploading" @click="close()" />

        <v-btn
          color="primary"
          :loading="uploading || importing"
          :disabled="
            !contentSelected ||
            (!!mediaFile && validateSlug(slug) !== true) ||
            (activeTab === 'import' && !validateFigmaImageInfo(figmaImage))
          "
          @click="confirmAdd()"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="figmaImageCorrectionOpen" width="1000">
    <v-card>
      <v-card-title>{{ figmaImage.name }}</v-card-title>

      <v-col>
        <v-textarea
          :model-value="figmaImage.info?.resource"
          label="Resource (slug)"
          hint="Semi-human readable name of the file"
          placeholder="eg introducing-oura-1"
          :error-messages="figmaIdErrorMessages"
          @update:model-value="figmaImage.info.resource = $event"
        />

        <v-textarea
          :model-value="figmaImage.info?.content"
          label="Content (Parent ID)"
          hint="Semi-human readable name of the parent"
          placeholder="eg content-welcome-to-oura"
          @update:model-value="figmaImage.info.content = $event"
        />

        <v-select
          v-model="figmaImage.info.section"
          label="Section"
          :items="sections"
          :error-messages="figmaSectionErrorMessages"
        />

        <v-select
          v-model="figmaImage.info.category"
          label="Category"
          :items="categories"
          :error-messages="figmaCategoryErrorMessages"
        />
      </v-col>
      <v-card-actions>
        <v-btn text="Close" @click="figmaImageCorrectionOpen = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { fuzzyMatch } from './utilities'

  import slug from 'slug'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import {
    availableFigmaFiles,
    contentCategories,
    contentIcons,
    figmaSections,
    imageHeaders,
    mediaHeaders,
  } from '#views/contents/constants'
  import { appLocales } from '#views/messages/constants'

  import { MediaStore, UploadsStore } from '#stores'

  import { isMediaIdentifierValid } from '#utilities'

  @Component({})
  class SelectMediaDialog extends Vue {
    @Prop({ default: () => [] }) public current!: string

    @Prop({ default: () => [] }) public vendors!: any[]
    @Prop({ default: () => [] }) public contents!: any[]

    @Emit('confirm')
    public emitConfirm(selected: any) {
      return selected
    }

    public type = ''
    public slug = ''
    public error = ''
    public filter = ''

    public activeTab = 'upload'

    public isOpen = false
    public showActive = false
    public variantUpload = false
    public generateThumb = false
    public updateExisting = false
    public activateVariant = false
    public figmaImageCorrectionOpen = false

    public language = appLocales[1]

    public icons = contentIcons
    public categories = contentCategories

    public sections = figmaSections
    public availableFigmaFiles = availableFigmaFiles
    public selectedFigmaFile = null

    public provider = 'oura'
    public category = contentCategories[0].value

    public selected: any[] = []
    public locales: string[] = []

    public figmaImage: any = null
    public fileroObject: any = null

    public mediaFile: File | null = null
    public thumbFile: File | null = null

    public previewUrl: string | null = null
    public previewThumbUrl: string | null = null

    private mediaStore = new MediaStore()
    private uploadsStore = new UploadsStore()

    public get media(): any {
      return (this.type ? this[(this.type + 'Files') as keyof SelectMediaDialog] : []).filter(
        (m: any) =>
          (m.slug.includes(this.filter.toLowerCase()) || (m.info?.content || '').includes(this.filter.toLowerCase())) &&
          this.type === m.media_type &&
          !this.contents?.find((c: any) => c.media === m.slug) &&
          ((this.showActive && m.state === 'active') || (!this.showActive && m.state !== 'active')),
      )
    }

    public get figmaMedia() {
      return this.figmaImages.filter(
        (i: any) => i.slug.includes(this.filter.toLowerCase()) && this.figmaThumbs.find((t) => t.slug === i.slug),
      )
    }

    public get headers() {
      return this.type === 'image' ? imageHeaders : mediaHeaders
    }

    public get languages() {
      const languages = appLocales.slice(1).filter((l) => !this.locales?.includes(l.value))

      if (!this.variantUpload) {
        languages.unshift({
          title: 'Any',
          value: 'any',
        })
      }

      return languages
    }

    public get listing() {
      return this.mediaStore.listing
    }

    public get fetching() {
      return this.mediaStore.fetching
    }

    public get importing() {
      return this.mediaStore.importing
    }

    public get uploading() {
      return this.uploadsStore.uploading
    }

    public get previewing() {
      return this.mediaStore.previewing
    }

    public get imageFiles() {
      return this.mediaStore.imageFiles
    }

    public get audioFiles() {
      return this.mediaStore.audioFiles
    }

    public get videoFiles() {
      return this.mediaStore.videoFiles
    }

    public get mediaFiles() {
      return ([] as any[]).concat(this.imageFiles, this.audioFiles, this.videoFiles)
    }

    public get figmaImages() {
      return this.updateExisting ? [this.mediaStore.figmaImage].filter(Boolean) : this.mediaStore.figmaImages
    }

    public get figmaThumbs() {
      return this.updateExisting ? [this.mediaStore.figmaThumb].filter(Boolean) : this.mediaStore.figmaThumbs
    }

    public get contentSelected() {
      return (
        (this.activeTab === 'import' && this.figmaImage) ||
        (this.activeTab === 'select' && this.fileroObject) ||
        (this.activeTab === 'upload' &&
          this.type !== 'image' &&
          this.provider &&
          this.category &&
          this.language &&
          this.mediaFile &&
          !this.variantUpload) ||
        (this.type !== 'image' && this.mediaFile && this.variantUpload && this.language) ||
        (this.type === 'image' && this.mediaFile && (this.thumbFile || this.generateThumb) && this.provider)
      )
    }

    public get figmaIdErrorMessages() {
      return this.validateId(this.figmaImage.info.resource)
        ? ''
        : "Media slug may contain only lowercase letters, numbers and '-' should be used to separate words."
    }

    public get figmaSectionErrorMessages() {
      return this.validateSection(this.figmaImage.info.section)
        ? ''
        : "Media slug may contain only lowercase letters, numbers and '-' should be used to separate words."
    }

    public get figmaCategoryErrorMessages() {
      return this.validateCategory(this.figmaImage.info.category) ? '' : 'Must be valid category, pick from dropdown'
    }

    @Watch('category')
    protected onCategoryChanged(val: string, prevVal: string) {
      if (val && !this.variantUpload) {
        const re = new RegExp(`^${prevVal}-`)
        this.slug = this.slug.replace(re, `${val}-`)
      }
    }

    @Watch('provider')
    protected onProviderChanged(val: string, prevVal: string) {
      if (val && !this.variantUpload) {
        const re = new RegExp(`-${prevVal}-`)
        this.slug = this.slug.replace(re, `-${val}-`)
      }
    }

    @Watch('selectedFigmaFile')
    protected onSelectedFigmaFileChanged() {
      this.mediaStore.previewFigmaImages({
        files: this.selectedFigmaFile ? [this.selectedFigmaFile] : [],
      })
    }

    @Watch('updateExisting')
    protected onUpdateExistingChanged() {
      if (this.updateExisting) {
        this.mediaStore.previewFigmaImages({
          slug: this.current.split('-').slice(1).join('-'),
          files: this.selectedFigmaFile ? [this.selectedFigmaFile] : [],
        })
      }
    }

    public open(type: string, parent?: any) {
      this.type = type

      this.slug = parent?.slug || ''
      this.locales = parent?.locales || []

      this.variantUpload = !!parent?.slug || false

      this.provider = parent?.provider || 'oura'
      this.category = parent?.category || this.categories[0].value

      this.mediaStore.listMediaFiles({
        types: [this.type + 's'],
      })

      this.isOpen = true
    }

    public close() {
      if (!this.uploading) {
        this.slug = ''
        this.filter = ''

        this.selected = []

        this.activeTab = 'upload'

        this.mediaFile = null
        this.thumbFile = null

        this.figmaImage = null
        this.fileroObject = null

        this.previewUrl = null
        this.previewThumbUrl = null

        this.showActive = false
        this.variantUpload = false
        this.generateThumb = false
        this.updateExisting = false

        this.language = appLocales[1]

        this.isOpen = false
      }
    }

    public async confirmAdd() {
      if (this.mediaFile) {
        await this.uploadMedia()
      } else if (this.figmaImage) {
        await this.importImage()
      } else {
        this.emitConfirm(this.fileroObject)

        this.close()
      }
    }

    public async uploadMedia() {
      this.error = ''

      let response = await this.uploadsStore.uploadMedia({
        slug: this.slug,
        file: this.mediaFile,
        info:
          this.type === 'image'
            ? { provider: this.provider }
            : {
                provider: this.provider,
                category: this.category,
              },
        variantInfo: this.type === 'image' ? { rendition: 'original' } : undefined,
        language: this.type !== 'image' ? this.language.value.replace('any', 'en') : undefined,
        thumbnail: this.type === 'image' && this.generateThumb ? { focus: 'center' } : undefined,
      })

      const uploadJobId = response?.data?.slugJobId

      if (this.type === 'image' && uploadJobId && !this.generateThumb) {
        response = await this.uploadsStore.uploadMedia({
          slug: this.slug,
          file: this.thumbFile,
          info: { provider: this.provider },
          variantInfo: { rendition: 'thumbnail' },
        })
      }

      if (response?.upload?.status === 200) {
        this.emitConfirm({
          slug: this.slug,
          media_type: this.type,
          upload_job_id: uploadJobId,
          info: {
            content_language: this.language,
            provider: this.provider,
            category: this.category,
          },
        })

        this.close()
      } else {
        this.error = 'Failed to upload the media, please report this in #waltari channel!'
      }
    }

    public async importImage() {
      this.error = ''

      const uploads: any[] = []

      const figmaImage = this.figmaImages.find((t: any) => t.slug === this.figmaImage.slug)
      //TODO: decide if we should fix thumb slug too
      const figmaThumb = this.figmaThumbs.find((t: any) => fuzzyMatch(t.slug, this.figmaImage.slug))

      if (this.figmaImage) {
        let response = await this.uploadsStore.uploadMedia({
          slug: figmaImage.slug,
          file: figmaImage.media_file,
          info: { provider: this.provider },
          variantInfo: { rendition: 'original' },
        })

        const uploadJobId = response?.data?.slugJobId

        if (uploadJobId) {
          uploads.push({
            name: figmaImage.name,
            file: figmaImage.file,
            node: figmaImage.node,
            imageUrl: figmaImage.media_file.url,
            presignedUrl: response.data.presignedUrl,
          })

          response = await this.uploadsStore.uploadMedia({
            slug: figmaThumb.slug,
            file: figmaThumb.media_file,
            info: { provider: this.provider },
            variantInfo: { rendition: 'thumbnail' },
          })
        }

        if (uploadJobId) {
          uploads.push({
            name: figmaImage.name,
            file: figmaThumb.file,
            node: figmaThumb.node,
            imageUrl: figmaThumb.media_file.url,
            presignedUrl: response.data.presignedUrl,
          })

          response = await this.mediaStore.importFigmaImages({ uploads })
        }

        if (uploadJobId && response?.data?.uploads) {
          this.emitConfirm({
            slug: figmaImage.slug,
            media_type: 'image',
            upload_job_id: uploadJobId,
          })

          this.close()
        } else {
          this.error = 'Failed to import the image, please report this in #waltari channel!'
        }
      } else {
        this.error = 'Failed to find the image, please report this in #waltari channel!'
      }
    }

    public validateSlug(value: string) {
      return this.variantUpload ||
        (value &&
          value.length > 20 &&
          value.includes('-') &&
          value === slug(value) &&
          !this.contents?.find((c: any) => c.id === value) &&
          !this.mediaFiles?.find((m: any) => m.slug === value))
        ? true
        : value
          ? 'Invalid slug format or media with this slug already exists!'
          : false
    }

    public validateId(id: string) {
      return isMediaIdentifierValid(id)
    }

    public validateCategory(category: string) {
      return this.categories.map((c) => c.value).includes(category)
    }

    public validateSection(section: string) {
      return this.sections.includes(section)
    }

    public validateFigmaImageInfo(figmaImage: any) {
      return (
        !figmaImage ||
        (this.validateId(figmaImage?.info?.content) &&
          this.validateId(figmaImage?.info?.resource) &&
          this.validateSection(figmaImage?.info?.section) &&
          this.validateCategory(figmaImage?.info?.category))
      )
    }

    public mediaFileSelected(file: File) {
      if (file) {
        this.selected = []

        this.figmaImage = null
        this.fileroObject = null

        const reader = new FileReader()

        if (!this.variantUpload) {
          this.slug =
            (this.type === 'image' ? 'content-background-' : `${this.category}-${this.provider}-`) +
            slug(file.name.split('.').slice(0, -1).join())
        }

        reader.onload = (e) => {
          this.previewUrl = e!.target!.result as string
        }

        reader.readAsDataURL(file)
      } else {
        if (!this.variantUpload) {
          this.slug = ''

          this.provider = 'oura'
          this.category = this.categories[0].value
        }

        this.language = appLocales[1]

        this.previewUrl = ''
      }
    }

    public async figmaImageSelected(item: any) {
      this.figmaImage = item

      this.mediaFile = null
      this.thumbFile = null

      this.fileroObject = null

      this.previewUrl = item.media_file?.url

      const figmaThumb = this.figmaThumbs.find((t: any) => t.slug === item.slug)

      this.previewThumbUrl = figmaThumb?.media_file?.url || null
    }

    public async fileroObjectSelected(item: any) {
      this.slug = ''

      this.mediaFile = null
      this.thumbFile = null
      this.figmaImage = null

      const mediaPreviewResponse = await this.mediaStore.getMediaInfo({
        slug: item.slug,
        type: item.media_type,
      })

      const variants = mediaPreviewResponse?.data?.info?.variants || []

      const index = variants.findIndex(
        (v: any) => v.state !== 'disabled' && (v.info.content_language === 'en' || v.info.content_language === 'zxx'),
      )

      this.fileroObject = {
        ...item,
        media_file: variants[index === -1 ? 0 : index]?.media_file,
      }

      this.previewUrl = this.fileroObject.media_file?.url
    }
  }

  export default toNative(SelectMediaDialog)
</script>

<style lang="scss" scoped></style>
