import { isEqual, orderBy, uniqWith } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { Timestamp, Unsubscribe, onSnapshot, query, where } from 'firebase/firestore'

import { collectionRef, docRef, getFsDocData, setFsDocData } from '#common/firebase'

import {
  AppBuild,
  AppBuildFirestorePaths,
  AppBuildTest,
  AppBuildTests,
  AppPlatform,
  AppReleaseCandidates,
  AppReleaseNotesFirestorePaths,
  AppTestFirestorePaths,
  PlatformMetadata,
  ReleaseNotes,
  ReleaseTypes,
  TestRunOutcomes,
  appReleases,
} from '#types'

let unsubscribes: Unsubscribe[] = []

@Store
export class ReleasesStore extends Pinia {
  public saving = false
  public loadingReleases = false

  public appBuilds: (AppBuild | Partial<AppBuild>)[] = []

  public appBuildTests: { [commit: string]: AppBuildTest[] } = {}

  public latestReleases: appReleases = {
    ios: {},
    android: {},
  }

  public previousReleases: appReleases = {
    ios: {},
    android: {},
  }

  public releaseNotes: { [type in ReleaseTypes]: { [platform in AppPlatform]: ReleaseNotes | undefined } } = {
    [ReleaseTypes.CANDIDATE]: { android: undefined, ios: undefined },
    [ReleaseTypes.LATEST]: { android: undefined, ios: undefined },
    [ReleaseTypes.PREVIOUS]: { android: undefined, ios: undefined },
  }

  public releaseCandidates: AppReleaseCandidates = { ios: '', android: '' }

  public get builds() {
    // Vuetify data table does not support group ordering,
    // so we need to order the builds manually
    return orderBy(uniqWith(this.appBuilds, isEqual), 'buildVersion', 'asc')
  }

  private set latestReleaseNotes(data: { platform: AppPlatform; notes: ReleaseNotes | undefined }) {
    this.releaseNotes[ReleaseTypes.LATEST][data.platform] = data.notes ?? undefined
  }

  private set previousReleaseNotes(data: { platform: AppPlatform; notes: ReleaseNotes | undefined }) {
    this.releaseNotes[ReleaseTypes.PREVIOUS][data.platform] = data.notes ?? undefined
  }

  /**
   * Overrides failed status from test automation with manual approval.
   */
  public async manuallyApproveFailedTests(platform: AppPlatform, commit: string) {
    const tests = await getFsDocData<AppBuildTests>(AppTestFirestorePaths[platform], commit)

    Object.values(tests).forEach(async (test) => {
      if (this.isTestObject(test) && test.testRunOutcome === TestRunOutcomes.FAILURE) {
        await setFsDocData(AppTestFirestorePaths[platform], commit, {
          [test.testRunType]: { testRunOutcome: TestRunOutcomes.APPROVED },
        })
      }
    })
  }

  public async updateReleaseNotesState(version: string, platform: AppPlatform, data: Partial<ReleaseNotes>) {
    try {
      return await setFsDocData(AppReleaseNotesFirestorePaths[platform], version, data)
    } catch (error) {
      console.error('Release note update failed', error)
    }
  }

  public async setReleaseCandidate(buildVersion: string, platform: AppPlatform) {
    try {
      const docId = 'platformMetadata'

      await setFsDocData(AppBuildFirestorePaths[platform], docId, { releaseCandidate: buildVersion })
    } catch (error) {
      console.error('doc update failed', error)
    }
  }

  public async fetchLatestVersion(platform: AppPlatform, fallback = '5.0.0') {
    const metaData = await getFsDocData<PlatformMetadata>(AppBuildFirestorePaths[platform], 'platformMetadata')
    const version = metaData?.latestBuildVersion ?? fallback
    const [major, minor, patch] = version.split('.').map((version: string) => parseInt(version))

    return { major, minor, patch }
  }

  /**
   * Subscribes to the latest builds, releases and release notes for both platforms.
   */
  public async subscribeToReleases() {
    Promise.all([
      this.subscribeBuilds('ios'),
      this.subscribeReleases('ios'),
      this.subscribeBuildTests('ios'),
      this.subscribeReleaseNotes('ios'),
      this.subscribeBuilds('android'),
      this.subscribeReleases('android'),
      this.subscribeBuildTests('android'),
      this.subscribeReleaseNotes('android'),
    ]).then((settled) => (unsubscribes = settled))
  }

  /**
   * Unsubscribes from all store subscriptions.
   */
  public async unsubscribeFromReleases() {
    unsubscribes.forEach((unsubscribe) => {
      if (unsubscribe) {
        unsubscribe()
      }
    })
  }

  /**
   * Subscribes to the latest builds for the given platform.
   */
  private async subscribeBuilds(platform: AppPlatform) {
    const { major, minor, patch } = await this.fetchLatestVersion(platform)
    const version = `${major}.${minor}.${patch}`

    return onSnapshot(
      query(collectionRef(`${AppBuildFirestorePaths[platform]}`), where('version', '==', version)),
      async (snap) =>
        snap.docs.forEach(async (document) => {
          const build = document.data() as AppBuild
          const { buildVersion, commit } = build

          const prevIndex = this.appBuilds.findIndex((build) => build.buildVersion === buildVersion)
          if (prevIndex !== -1) {
            this.appBuilds.splice(prevIndex, 1)
          }

          this.appBuilds.push(build)

          const testsWithId = await getFsDocData<AppBuildTests>(AppTestFirestorePaths[platform], commit)
          const tests = Object.values(testsWithId).filter((test) => this.isTestObject(test))

          this.appBuildTests[commit] = tests
        }),
    )
  }

  /**
   * Subscribes to the latest and previous to latest public releases.
   */
  private async subscribeReleases(platform: AppPlatform) {
    const buildsPath = AppBuildFirestorePaths[platform]
    const rnPath = AppReleaseNotesFirestorePaths[platform]

    return onSnapshot(docRef(buildsPath, 'platformMetadata'), async (document) => {
      const { releaseCandidate, currentPublicRelease, previousPublicRelease } = document.data() as PlatformMetadata
      const { version: latestVersion, build: latestBuild } = currentPublicRelease
      const { version: prevVersion, build: prevBuild } = previousPublicRelease

      this.releaseCandidates[platform] = releaseCandidate ?? ''

      if (latestVersion && latestBuild) {
        this.latestReleases[platform] = (await getFsDocData<AppBuild>(buildsPath, latestBuild)) ?? {}
        this.latestReleaseNotes = { platform, notes: await getFsDocData<ReleaseNotes>(rnPath, latestVersion) }
      }

      if (prevVersion && prevBuild) {
        this.previousReleases[platform] = (await getFsDocData<AppBuild>(buildsPath, prevBuild)) ?? {}
        this.previousReleaseNotes = { platform, notes: await getFsDocData<ReleaseNotes>(rnPath, prevVersion) }
      }
    })
  }

  /**
   * Tests are initiated during releases subscription.
   * This subscription keeps local tests up to date.
   */
  private async subscribeBuildTests(platform: AppPlatform) {
    return onSnapshot(collectionRef(`${AppTestFirestorePaths[platform]}`), async (snap) =>
      snap.docs.forEach(async (document) => {
        if (document.exists() && document.id in this.appBuildTests) {
          const tests = Object.values(document.data()).filter((test) => this.isTestObject(test))

          this.appBuildTests[document.id] = tests
        }
      }),
    )
  }

  /**
   * Release notes are initiated during releases subscription.
   * This subscription keeps local release notes up to date.
   */
  private async subscribeReleaseNotes(platform: AppPlatform) {
    const { major, minor, patch } = await this.fetchLatestVersion(platform)

    return onSnapshot(
      docRef(AppReleaseNotesFirestorePaths[platform], `${major}.${minor}.${patch}`),
      async (document) => {
        if (document.exists()) {
          this.releaseNotes[ReleaseTypes.CANDIDATE][platform] = document.data() as ReleaseNotes
        }
      },
    )
  }

  private isTestObject(test: AppBuildTest | string | Timestamp | undefined): test is AppBuildTest {
    if (!test) {
      return false
    }

    return typeof test === 'object' && Object.hasOwn(test, 'testRunOutcome')
  }
}
